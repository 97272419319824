<template>
	<v-card flat id="evidence_table">
		<v-card-title>
			Table of Indicative Evidence
		</v-card-title>
		<v-card-text>
			<v-simple-table>
				<thead>
					<tr>
						<th>Digital Maturity Tool Theme - Section</th>
						<th>Indicative Evidence</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Readiness – Strategic Alignment</td>
						<td>
							<ul>
								<li>
									Digital vision strategy document and road map.
								</li>
								<li>
									Service transformation programme document and strategy to evidence alignment with digital vision and strategy.
								</li>
								<li>
									Terms of reference for Design Authority / equivalent group.
								</li>
								<li>
									Project pipelines and minutes evidencing prioritisation, time to implementation and feedback to users regarding the process to support evidencing ‘effectiveness’ of the group.
								</li>
								<li>
									Project pipeline supports evidencing of innovation.
								</li>
								<li>
									Evidence service improvement projects supported by technology, including deliverables and outcomes.
								</li>
								<li>Comms and engagement strategy.</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Readiness – Leadership</td>
						<td>
							<ul>
								<li>
									Annual board agenda and plan indicating frequency of board profile.
								</li>
								<li>
									Documented updates to digital strategy, including those reported to the Board.
								</li>
								<li>
									Board minutes evidencing discussion and agreed actions.
								</li>
								<li>
									Role profile / job description of relevant Executive Director responsible for the digital agenda.
								</li>
								<li>
									Career history and relevant experience of non-executive director with responsibility for technology Role profile/job description of Chief Information Officer (CIO)/Chief Digital Officer (CDO), or equivalent capabilities
								</li>
								<li>
									Role profile/job description of social care professional lead for digital.
								</li>
								<li>
									Documented engagement with users at all levels in the organisation.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Readiness – Resourcing</td>
						<td>
							<ul>
								<li>
									Procurement strategy and the alignment with the digital vision.
								</li>
								<li>
									Contract management plan and governance arrangements for large (£1m+) suppliers. Latest copy of service performance metrics where appropriate.
								</li>
								<li>
									Benefits realisation performance matrix: benefit type with phased financial delivery trajectory. Worked example of a benefits line, identifying the operational process to realise the cash benefits.
								</li>
								<li>
									Whole Time Equivalent (WTE) employed in the services described to provide a headline understanding of the capacity.
								</li>
								<li>
									Evidence of clinical safety assessments, including safety cases and hazard logs
								</li>
								<li>
									Role profile/job description for Clinical Safety Officer
								</li>
								<li>
									Organisation chart, including roles and responsibilities for technology and transformation functions.
								</li>
								<li>Training profiles by staff type.</li>
								<li>
									Ratio of devices to users, average system log-on times, and user satisfaction feedback or surveys.
								</li>
								<li>
									Three-year workforce plan for technology capability: evidencing scope of skills sets required: technical, change management, programme management, clinical and communications skill sets.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Readiness – Governance</td>
						<td>
							<ul>
								<li>
									Digital programme summary document.
								</li>
								<li>
									Programme governance structure, board membership, and minutes to evidence attendance.
								</li>
								<li>
									Business cases to support digital programme(s).
								</li>
								<li>
									Benefits realisation plan and transformation / cost improvement plan, supported by evidence to demonstrate no double counting.
								</li>
								<li>
									Evidence to support the adoption of technology and use of standards. Expectation to be evidenced through policies.
								</li>
								<li>
									Information and clinical risk management strategies, assessments and resulting action plans.
								</li>
								<li>
									Examples of the application of best practice guidelines in practice
								</li>
								<li>
									Lessons learned reports or root cause analyses.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Readiness – Skills and Competences</td>
						<td>
							<ul>
								<li>
									Details of digital training programmes offered, including basic digital literacy, application training for health and care professionals and number of staff trained
								</li>
								<li>
									Service user feedback about training
								</li>
								<li>
									Number of digital related CPD courses offered to staff
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Capabilities - Call Management</td>
						<td>
							<ul>
								<li>
									Number of calls received compared to the planned or contracted average
								</li>
								<li>
									Percentage of calls answered within the designated NHS target thresholds for the service, 999, 111 & PTS
								</li>
								<li>
									Average handling times for the different call types within the call centres designated.
								</li>
								<li>
									Percentage of available capacity during peak time call demand in both technology infrastructure and available agents.
								</li>
								<li>
									Percentage of abstraction or shrinkage for call handling staff in the call centres designated
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Capabilities - Call Recording</td>
						<td>
							<ul>
								<li>
									Number of calls recorded on the voice recording equipment over any given period for each designated centre
								</li>
								<li>
									Number of calls not recorded on the voice recording equipment over any given period for each designated centre
								</li>
								<li>
									Number of calls accessed by third party organisations over any given period
								</li>
								<li>
									Percentage of calls that are accessed by call handlers via a rapid access recorder to verify the content of calls
								</li>
								<li>
									Data storage usage and capacity for voice recordings
								</li>
								<li>
									Percentage of voice recording requests that cannot be found following a search.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>
							Capabilities - Triage, Dispatch and Resource Allocation
						</td>
						<td>
							<ul>
								<li>
									Percentage of call handling compliance achieved by call handlers in relation to the quality standards set by the AMPDS or NHS Pathways triage platforms
								</li>
								<li>
									Average times to allocate 999 incidents by category of incidnet
								</li>
								<li>
									Number of incidents where the CAD is used to recommend a resource compared to the dispatcher reaching a dispatch decision without the aid of the CAD
								</li>
								<li>
									Percentage of incidents that are dispatched via an auto dispatch facility by category of incident.
								</li>
								<li>
									Percentage of incidents by category where a responding resource is re-tasked by a dispatcher before the incident is attended"
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>
							Capabilities – Records, Assessments and Plans
						</td>
						<td>
							<ul>
								<li>
									Percentage of historic records scanned in relation to the whole archive. Evidence to show how these records are made available and are integrated with the contemporaneous records.
								</li>
								<li>
									User log in numbers to main electronic health record applications
								</li>
								<li>
									Functionality to enable health and care professionals to access systems and data while off site.
								</li>
								<li>
									Evidence to demonstrate the volume and usage of pre-populated order sets, template summaries and letters.
								</li>
								<li>
									Point of care information capture: Percentage of Incidents where clinical documentation is captured digitally, and the percentage of secondary practitioner visits were clinical documentation is captured digitally. Percentage of care assessments and care plans captured
									digitally.
								</li>
								<li>
									Evidence to demonstrate ease of use, e.g. average log-on times, single sign-on, context management and the ability to enter data into native systems through a single portal. Use of voice recognition technologies or digital dictation to support data entry.
								</li>
								<li>
									Percentage of patients treated with reference to their historical notes or records for regular callers
								</li>
								<li>
									Percentage of social care type incidents that have been assessed or followed up with reference to their historic digital case records.
								</li>
								<li>
									Information received and sent digitally to external health and care organisations
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Capabilities – Transfers of Care</td>
						<td>
							<ul>
								<li>
									Percentage of referrals sent digitally in relation to total number of referrals
								</li>
								<li>
									Evidence to demonstrate compliance with the College of Paramedics documentation standards, in terms of patient observations and patient history layout and content.
								</li>
								<li>
									Number of patient referals transmitted digitally to GPs or other health and care professionals – what format?
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Capabilities – Medicines Management</td>
						<td>
							<ul>
								<li>
									Range of medicines prescribed electronically, including consideration for infusions, sliding scale, variable dose and rate regimens, paediatric dose checking.
								</li>
								<li>
									Order sets in place for common conditions and the use of reference sources like the British National Formulary.
								</li>
								<li>
									Process for administration, patient identification and monitoring of doses, interactions, timings, including process for alerting of missed medications.
								</li>
								<li>
									Evidence to demonstrate compliance monitoring of alerts.
								</li>
								<li>
									Process for monitoring antibiotic practice and stewardship with Paramedic Practitioners.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>
							Capabilities – Asset and Resource Optimisation
						</td>
						<td>
							<ul>
								<li>
									The use of Radio Frequency Identification (RFID) tags on equipment and evidence of analysis performed e.g. utilisation, location etc.
								</li>
								<li>
									Rostering functionality, including coverage across the organisation, evidence of monitoring the effectiveness of rostering, and documented management action to improve.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>
							Capabilities - Security and Business Continuity
						</td>
						<td>
							<ul>
								<li>
									Number of recorded security breaches or attempted breeches of the indicated platforms over a given period
								</li>
								<li>
									Access control arrangements for the indicated control room sites
								</li>
								<li>
									Number of business continuity incidents declared over any given period
								</li>
								<li>
									Number of training exercises conducted for any given period to simulate system failure events
								</li>
								<li>
									Number of hours that any of the designated control room environment could operate or function without core systems being available"
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>
							Capabilities – Business & Clinical Intelligence
						</td>
						<td>
							<ul>
								<li>
									Accessibility of data for staff members via fit-for-purpose dashboards.
								</li>
								<li>
									Process for data-driven improvement of services.
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td>Enabling Infrastructure</td>
						<td>
							<ul>
								<li>Wi-fi usage statistics</li>
								<li>Mobile data usage statistics</li>
								<li>
									Staff surveys indicating the level of satisfaction with IT support
								</li>
								<li>
									Service desk metrics and user satisfaction surveys.
								</li>
								<li>
									Asset registers detailing software and hardware in use in the organisation, including licenses.
								</li>
								<li>
									Processes for the resolution of IT issues
								</li>
								<li>
									Bring Your Own Device policy and number of own devices used
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: "EvidenceTable",
};
</script>
