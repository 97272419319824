<template>
	<v-sheet flat>
		<content-contents :value="ids">
			<template v-slot:prepend>
				<p>You can find instructions for the most important functions of this tool on this page. Need more help? Get in touch with <a router-link to="/contact">support!</a></p>
			</template>

			<template v-slot:append>
				<v-list-item href="#evidence_table">
						<v-list-item-icon>
							<v-icon>mdi-chevron-right</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								Table of Indicative Evidence
							</v-list-item-title>
						</v-list-item-content>
				</v-list-item>
			</template>
		</content-contents>
		<h2 class="pl-5 mb-2">Tutorials</h2>
		<content-list :value="ids">
			<template v-slot:append>
				<v-card flat>
					<v-simple-table>
						<tbody>
							<tr>
								<td>Disagree Completely</td>
								<td>
									The statement is relevant to my organisation but does not apply to it.
								</td>
							</tr>
							<tr>
								<td>Somewhat Disagree</td>
								<td>
									The statement applies to a minority share of my organisation. Some aspect of the statement applies, but not all of it.
								</td>
							</tr>
							<tr>
								<td>Neither Agree nor Disagree</td>
								<td>
									The statement applies to about half of the organisation.
								</td>
							</tr>
							<tr>
								<td>Somewhat Agree</td>
								<td>
									Most of the statement applies, but not all of it. The statement applies generally, but there are exceptions.
								</td>
							</tr>
							<tr>
								<td>Agree Completely</td>
								<td>
									The statement applies to all relevant parts of my organisation.
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card>
				<v-card flat class="ma-5">
					<v-card-text>
						<h5 class="pt-4">Readiness Example</h5>
						<p class="pt-5">
							Question: Your organisation has a digital strategy, aligned with clinical/corporate objectives, that effectively drives transformation. Tooltip: A digital strategy needs to link clinical and business objectives of the organisation to digital technology solutions that support
							them. The focus is on whole system service redesign rather than digitisation of current paper processes.
						</p>
					</v-card-text>
				</v-card>
				<v-simple-table>
					<tbody>
						<tr>
							<td>Disagree Completely</td>
							<td>Nothing like a digital strategy exists.</td>
						</tr>

						<tr>
							<td>Somewhat Disagree</td>
							<td>There’s a strategy, but it doesn’t cover most of what you might expect it to. There’s a digital strategy, but it’s very incomplete and/or out of date.</td>
						</tr>

						<tr>
							<td>Neither Agree nor Disagree</td>
							<td>There’s no formal digital strategy, but some of the contents are included in other documents. There is an agreed plan for how the organisation should prioritise digital investment but it hasn’t been drawn together into a digital strategy.</td>
						</tr>

						<tr>
							<td>Somewhat Agree</td>
							<td>There’s a digital strategy, but it needs some updating. Our digital strategy is up to date but has a few gaps.</td>
						</tr>

						<tr>
							<td>Agree Completely</td>
							<td>There’s a completely up to date and comprehensive digital strategy that is aligned to the clinical/business objectives of the organisation and to wider strategic integration goals.</td>
						</tr>
					</tbody>
				</v-simple-table>
				<v-card flat class="ma-5">
					<v-card-text>
						<h5 class="pt-4">Capabilities Example</h5>
						<p class="pt-5">
							Question: Health and care professionals can update digital records, or relevant components of them, at the point of care as part of their regular day-to-day routine. Tooltip: Information can be updated by health and care professionals in any situation where care decisions are
							made.
						</p>
					</v-card-text>
				</v-card>
				<v-simple-table>
					<tbody>
						<tr>
							<td>Disagree Completely</td>
							<td>Ambulance professionals (whether registered or unregistered) record all information on paper.</td>
						</tr>
						"
						<tr>
							<td>Somewhat Disagree</td>
							<td>
								Some Ambulance professionals (whether registered or unregistered) record information about patients/clients at the point of care but some staff groups or departments don’t update digital records at all. There is still a significant dependency on paper to record information
								about patient/client care.
							</td>
						</tr>

						<tr>
							<td>Neither Agree nor Disagree</td>
							<td>Patient/client information is updated at the point of care by about half the organisation but it depends on whether staff can access systems remotely. Some information is recorded in digital records and some is recorded in patient/client paper based case records.</td>
						</tr>

						<tr>
							<td>Somewhat Agree</td>
							<td>
								Digital records are available and updated routinely at the point of care in most departments and by the majority of health and care professionals Some Ambulance professionals (whether registered or unregistered) working remotely within the organisation update digital records
								routinely at the point of care but other staff need to wait until they return to base to update records.
							</td>
						</tr>

						<tr>
							<td>Agree Completely</td>
							<td>All Ambulance professionals (whether registered or unregistered) throughout the organisation</td>
						</tr>
					</tbody>
				</v-simple-table>
				<evidence-table class="mt-8"></evidence-table>
			</template>
		</content-list>
	</v-sheet>
</template>

<script>
import ContentContents from "@c/ui/ContentContents";
import ContentList from "@c/ui/ContentList";
import EvidenceTable from "./EvidenceTable.vue";
export default {
	name: "Tutorials",
	data: () => {
		return {
			ids: [
				"tutorial1_workingwiththedma",
				"tutorial2_loggingon",
				"tutorial3_assessmentpage",
				"tutorial4_enteringinfo",
				"tutorial5_saving",
				"tutorial6_delegating",
				"tutorial7_polling",
				"tutorial7_staffsurvey",
				"tutorial9_trackingprogress",
				"tutorial10_submittingevidence",
				"submittingyourassessment",
				"tutorial12_gettinghelp",
				"tutorial13_resttingpassword",
				"tutorial13_scales",
			],
		};
	},
	components: {
		EvidenceTable,
		ContentContents,
		ContentList,
	},
	computed: {},
};
</script>
